@import 'variables';
@import '../table.scss';

.rd-row-cp {
  @include design-table;
  padding: 0 $pagePadding 0 $pagePadding;
  display: flex;
  flex: 0 0 auto;
  &.active {
    background-color: $grey200;
  }
  &.editing {
    .conf {
      display: none;
      flex: 0 0 0px;
      overflow: hidden;
      .conf-btn {
        margin-left: 2vw;
      }
      .action {
        background-color: green;
      }
    }
  }
  &:hover {
    .actions {
      .fas, .far {
        animation: slowReveal 1s 1 linear;
        opacity: 1;
      }
    }
  }
  .actions {
    .fas, .far {
      color: $grey500;
      opacity: .1;
      &:not(:first-child) {
        padding-left: $defaultPadding;
      }
      transition: $defaultTransitionDuration opacity;
      cursor: pointer;
      &:hover {
        color: $accentColor;
      }
    }
  }
}


@keyframes slowReveal {
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
