@import 'variables';

.dropz-comp {
  .thumb {
    display: inline-flex;
    border: 3px solid transparent;
    margin-bottom: $defaultPadding;
    margin-right: $defaultPadding;
    display: flex;
    &:hover {
      border: 3px dashed $defaultBorderColor;
    }
    &.drag {
      border: 3px dashed $accentColor;
      background-color: $grey200;
    }
    .thumbInner {
      display: flex;
      flex: 1;
      > div {
        &:first-child {
          flex: 0 0 200px;
          width: 200px;
          height: 200px;
          max-width: 200px;
          min-height: 200px;
          max-height: 200px;
          background-size: contain;
          background-repeat: no-repeat;
        }
        &:last-child {
          padding: $largePadding $defaultPadding;
          flex: 1;
          display: flex;
          flex-direction: column;
          > div {
            text-align: center;
            &:last-child {
              padding: $defaultPadding;
              color: $grey500;
              font-size: xx-large;
            }
          }
        }
      }
    };
  }
}
