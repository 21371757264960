@import 'variables';

.tool-family-editor {
  padding: $defaultPadding $pagePadding $defaultPadding $pagePadding;
  display: flex;
  flex-direction: column;
  flex-grow: 4;

  input {
    &[type='checkbox'] {
      margin: 0;
      padding: 0;
    }
  }

  i {
    cursor: pointer;
  }

  .top {
    display: flex;
    flex-direction: row;
  }

  .title {
    color: $tableHeaderColor;
    margin-bottom: $defaultPadding;
  }

  .main {
    display: flex;
    flex-direction: row;

    .left {
      display: flex;
      flex-direction: column;
      flex-grow: 3;
    }

    .right {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-left: $defaultPadding;
    }
  }

  .bottom {
    padding-top: $defaultPadding;
    text-align: end;
  }

  .dimr-clicked {
    position: relative;
    top: -60px;
    left: 250px;

    .w {
      display: inline-flex;

      .small > .loader {
        margin: 0;
      }
    }
  }

  .tool-family-table {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.tool-selection {
      .name {
        width: 54%;
        text-transform: uppercase;
        font-weight: bold;
      }

      .number {
        width: 30%;
      }

      .action {
        width: 8%;
        text-align: right;
      }
    }

    &.markets {
      .name {
        width: 80%;
        text-transform: uppercase;
        font-weight: bold;
      }

      .action {
        width: 20%;
        text-align: right;
      }
    }

    .list-header {
      @include list-heading();
      flex: 0 0 auto;
      display: flex;
      justify-items: center;
    }

    .row-container {
      max-height: calc(100vh - (#{$menuHeight} + 115px + #{$defaultPadding}* 5));
      overflow: auto;
      flex-grow: 1;

      .list-row {
        display: flex;
        align-items: center;
        padding: $listPaddingVertical $defaultPadding;
        font-size: 100%;
        transition: $defaultTransitionDuration background-color;
        &:hover {
          background-color: $grey300;
        }

        &.selected {
          background-color: $grey200;
        }

        .delete {
          transition: $defaultTransitionDuration color;
          &.disabled {
            opacity: .1;
            cursor: not-allowed;
            i {
              cursor: not-allowed;
            }
          }

          &:not(.disabled):hover {
            color: $accentColor;
          }
        }
      }
    }

    .list-footer {
      padding: $listPaddingVertical $defaultPadding;
      display: flex;
      flex-direction: column;
    }

    .row-container + .list-footer {
      border-top: 2px solid $grey300;
    }
  }
}
