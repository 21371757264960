@import 'variables';
@import './table.scss';

.rd-tbl-cp {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;

  &>.body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .step-rows {
      flex: 0 0 auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }
  .editor {
    @include design-table-edit-panel();
    &.empty {
      padding: $defaultPadding 4vw ;
    }
    display: flex;
  }
  .no-btn {
    background: transparent;
    border: none;
    color: $linkColor;
    padding: 0;
    font-weight: normal;
    font: 100% 'Trade Gothic', Helvetica, sans-serif;
    transition: $defaultTransitionDuration color;
    &:hover {
      color: $accentColor;
    }
  }
}
