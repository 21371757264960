@import 'variables';

.is {
  padding: $smallPadding calc(#{$defaultPadding} - 8px);
  margin: auto;
  width: auto;
  &.white {
    select {
      border-bottom: 0;
    }
  }

  &.disabled {
    cursor: not-allowed;
    select {
      cursor: not-allowed;
    }
  }

  &.on-white {
    border-top: 1px solid $bgfill;
    border-left: 1px solid $bgfill;
    border-bottom: 1px solid $grey200;
    border-right: 1px solid $grey200;
    border-radius: $defaultBorderRadius;
  }
  &:not(.on-white) {
    select {
      border: 0;
    }
  }

  label {
    display: block;
    font-size: 0.8em;
    font-weight: bold;
    margin-bottom: 4px;
  }

  select {
    margin: auto;
    background-color: transparent;
    font: 100% 'Trade Gothic', Helvetica, sans-serif;
    border: none;

    &:focus {
      outline: none;
      animation: simpleinputcolorchange .5s;
    }
  }
}
