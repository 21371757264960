@import 'variables';
// https://medium.muz.li/30-handpicked-excellent-dashboards-347e2407a057

$segUpperHeight: 30vh;
$segHeaderHeight: 56px;
$segFooterHeight: 70px;

.seg {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  .segment {
    &.menu {
      flex: 0 0 68px;
      overflow: hidden;
    }
    &.up3rd {
      flex: 0 0 $segUpperHeight;
      overflow: auto;
      @media (max-height: 699px) {
        flex: 0 0 260px;
      }
    }
    &.header {
      flex: 0 0 $segHeaderHeight;
      overflow: hidden;
      background-color: $white;
    }
    &.body {
      overflow: auto;
      flex: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
      background: $white;
    }
    &.footer {
      overflow: hidden;
      flex: 0 0 $segFooterHeight;
      background: $white;
    }
  }

}
