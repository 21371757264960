@import 'variables';

.rsi-comp {
  flex: 1;
  padding: $listPaddingVertical $defaultPadding;
  font-weight: bold;
  text-transform: uppercase;
  transition: all $defaultTransitionDuration;
  cursor: pointer;
  &.selected {
    color: $accentColor;
    background-color: $grey200;
  }
  &.unavailable {
    color: $grey300;
    &.selected {
      color: lighten($accentColor, 15%);
    }
    &:hover {
      background-color: $grey200;
      color: $grey500;
    }
  }
  &:hover {
    background-color: $grey300;
  }
}
