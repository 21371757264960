@import 'variables';

button {
  font-family: 'Trade Gothic', sans-serif;
  font-weight: 600 !important;
  font-size: 14px;
  border-radius: $inputBorderRadius;
  cursor: pointer;
  background-color: $grey200;

  &.tail-spaced {
    margin: 0 $defaultPadding $smallPadding 0;
  }
  &.large {
    padding: $defaultPadding $largePadding;
    border-radius: $buttonBorderLargeRadius;
    font-size: 100%;
  }
  &:not(.large) {
    padding: $smallPadding $defaultPadding;
  }
  &.link {
    border-color: transparent;
    color: $accentColor;
  }

  &.primary {
    border-radius: $buttonBorderRadius;
    border-color: $accentColor;
    background-color: $accentColor;
    color: $white;
    transition: $defaultTransitionDuration background-color;
    font-weight: bold;

    &:disabled {
      background-color: lighten($accentColor, 15%);
      border-color: lighten($accentColor, 15%);
      cursor: not-allowed;

      &:hover {
        background-color: lighten($accentColor, 15%);
        border-color: lighten($accentColor, 15%);
      }
    }

    &:hover {
      background-color: $accentColorActive;
    }
    &:focus {
      background-color: $accentColorActive;
      outline: none;
    }
  }
  &.secondary {
    font-weight: bold;
    border-radius: $buttonBorderRadius;
    border-color: $grey500;
    background-color: $grey500;
    color: $white;
    transition: $defaultTransitionDuration background-color;

    &:hover {
      background-color: $grey800;
    }
    &:focus {
      background-color: $grey800;
      outline: none;
    }
  }
}
div.boxy {
  @include box();
  padding: $defaultPadding;
  .header {
    display: flex;
    h1,
    h2 {
      flex: 1;
      margin-top: 0;
      font-weight: 800 !important;
    }
    .actions {
      flex: 1;
      text-align: right;
    }
  }
}

div.values {
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1;
    padding: 0 0 $smallPadding 0;
    &:first-child {
      @include form-label();
    }
  }
  .value {
    padding: 0 0 $largePadding 0;
  }
}

div.form {
  &:not(.inline) {
    @include box($grey200);
  }
  &.inline {
    border: 1px solid $grey300;
    border-radius: $inputBorderRadius;
    background: $bgfill;
  }

  display: flex;
  flex-direction: column;
  margin: auto;
  &.tail-padded {
    padding-right: $defaultPadding;
  }
  &.full-width {
    & > div,
    & > label > div {
      display: flex;
      & > div {
        flex: 1;
        display: flex;
        & > * {
          flex: 1;
        }
      }
    }
  }
  & > div,
  & > label > div {
    flex: 1;
    &:first-child {
      color: $grey500;
      padding: $smallPadding 0 0 $defaultPadding;
      text-transform: uppercase;
      font-size: 80%;
    }
    &.form-text {
      padding: 2px 0 $smallPadding $defaultPadding;
    }
  }

  ul {
    list-style: none;
    li {
      cursor: pointer;
      padding: $defaultPadding;
      transition: $defaultTransitionDuration all;
      color: $linkColor;
      &:hover {
        color: darken($linkColor, 10%);
        background-color: darken($bgfill, 1%);
      }
    }
  }
}

ul.form {
  list-style: none;
}

.select-wrapper {
  display: flex;
  justify-content: flex-start;
  & > div {
    flex: 0 0;
  }
}

div {
  &.page-title {
    @include heading();
    margin: 0;
  }
  &.card-title {
    @include heading(120%, $smallPadding);
    margin: 0;
  }
  &.form-label {
    @include form-label();
  }
}