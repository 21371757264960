@import 'variables';

.ruse {
  margin-bottom: $defaultPadding;
  > div {
    display: flex;
    > div {
      padding: $listPaddingVertical 0;

      flex: 1;
      
    }
    &.header{
      @include heading(110%)
    }
  }
}
