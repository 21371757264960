@import 'variables';

.login-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    background-image: url('../../../style/images/HC750-0035.jpg');
    background-attachment: fixed;
    background-position-y: center;
    background-position-x: 55%;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-header {
    margin-top: 100px;
    background-image: url('../../../style/images/tos-diamond.png');
    background-repeat: no-repeat;
    background-color: #292929;
    background-position: center;
    height: 110px;
}

.login-form {
    background-color: #f3f2f0;
    padding: $defaultPadding;
}

.login-card-width {
    margin: 15vh $defaultPadding auto $defaultPadding;
    flex: 0 1 500px;
}

.button {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    color: #fff;
    font-size: 15px;
    border: 0;
    width: 100%;
    -webkit-transition: background-color 02s;
    background-color: #4b5762;
}
