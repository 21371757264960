@import 'variables';

@font-face {
  font-family: 'Trade Gothic';
  src: url('/fonts/HusqvarnaGothic-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Trade Gothic';
  src: url('/fonts/HusqvarnaGothic-Bold.woff') format('woff');
  font-weight: bold;
}

@keyframes simpleinputcolorchange {
  0%  {background: white;}
  50%   {background: $grey200;}
  100%  {background: white;}
}

.card {
  @include box();
  padding: $defaultPadding;
}

body {
  font: 110% 'Trade Gothic', Helvetica, sans-serif;
  background-color: $bgfill;
  color: $grey800;
  min-height: 100vh;
}

h1, h2, h3, h4, h5, nav {
  font-family: 'Trade Gothic', sans-serif;
  font-weight: 100 !important;
}

.uppercase {
  text-transform: uppercase;
}

.flex {
  display: flex;
  > * {
    flex: 1;
  }

  &.flex-col {
    flex-direction: column;
  }

  &.justify-between {
    justify-content: space-between;
  }
}

.flex-grow {
  flex-grow: 1;
}

a {
  text-decoration: none;
  color: $linkColor;
  cursor: pointer;
  transition: color $defaultTransitionDuration;
  &:hover {
    color: $accentColor;
  }
}
div.cols {
  display: flex;
  flex-direction: row;
  &>div {
    flex: 1;
    &.right-align {
      text-align: right;
      padding: $defaultPadding 0 0 $defaultPadding;
    }
  }
}
div.bumper {
  padding: 0 $defaultPadding $defaultPadding 0;
}
