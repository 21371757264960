@import 'variables';
@import '../table.scss';

.rd-row-cp {
  &.rd-footer {
    .steps {
      flex: 1;
      button {
        margin-right: $defaultPadding;
      }
    }
    .config {
      flex: 1;
      justify-content: flex-end;
      text-align: right;
    }
  }
}
