@import 'variables';
.msg-c {
  .tos-success {
    background-color: $green500;
    .tos-message-body {
      color: $grey800;
    }
  }
  .tos-info {
    background-color: $blue500;
    .tos-message-body {
      color: $grey200;
    }
  }
  .tos-error {
    background-color: $orange500;
  }
}
