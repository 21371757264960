@import "variables";

.content-list {
  height: 100%;
  // width: 400px;
  display: flex;
  flex-direction: column;
  margin-right: $defaultPadding;

  .list-header {
    @include list-heading();
    flex: 0 0 auto;
    display: flex;
    justify-content: space-evenly;
    justify-items: center;

    span {
      font-size: 110%;
      text-align: left;
      width: 100%;
    }

    & > a:first-of-type {
      border-radius: $defaultBorderRadius 0 0 $defaultBorderRadius;
    }

    & > a:last-of-type {
      border-radius: 0 6px 6px 0;
    }

    & > a {
      white-space: nowrap;
      color: $white;
      font-size: 110%;
      font-weight: bold;
      transition: $defaultTransitionDuration all;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      background-color: $grey300;
      padding: $smallPadding;

      &:hover {
        background-color: $grey500;
      }
      &.selected {
        background-color: $accentColor;
        font-weight: bold;
      }
    }
  }

  .row-container {
    max-height: calc(100vh - (#{$menuHeight} + 115px + #{$defaultPadding}* 5));
    overflow: auto;
    flex-grow: 1;

    .list-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $listPaddingVertical $defaultPadding;
      font-size: 100%;
      font-weight: bold;
      transition: all $defaultTransitionDuration;
      cursor: pointer;
      text-transform: uppercase;

      .name {
        margin-right: $largePadding;
      }

      &:hover {
        background-color: $grey300;
      }

      &.selected {
        background-color: $grey200;

        .name {
          color: $accentColor;
        }
      }
    }

    .sub-list-rows > .list-row > .name {
      padding-left: $largePadding;
      font-weight: normal;
    }

    .symbols {
      .fa-eye-slash{
        margin-right: $smallPadding;

      }
      &.disabled {
        .fa-trash-alt {
          opacity: 0.1;
        }
      }

      &:not(.disabled):hover {
        .fa-trash-alt {
          color: $accentColor;
        }
      }
    }
  }

  .list-footer {
    padding: $smallPadding;
    border-top: 1px solid $grey300;
    display: flex;
    justify-content: flex-end;
    flex-grow: 0;

    button {
      outline: none;
      border: 0;
      border-radius: 50%;
      background-color: $accentColor;
      color: $white;
      font-weight: bold !important;
      padding: 6px;
      font-size: 150%;
      width: 40px;
      height: 40px;
      margin-left: $smallPadding;

      &:before {
        content: '+';
      }

      &.text {
        width: auto;
        height: auto;
        border-radius: $defaultBorderRadius;
        font-size: 100%;
        text-transform: uppercase;
      }
    }
  }
}
