@import 'variables';

.users-sec-c {
  padding: $defaultPadding $pagePadding;
  display: flex;
  flex: 1;
  .user-item {
    position: fixed;
    max-height: 85vh;
    overflow: scroll;
    left: 35vw;
    width: 60vw;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &.list {
      max-height: 90vh;
      overflow: auto;
    }
    .header {
      &.li-head {
        @include list-heading();
        border-bottom: 1px solid $defaultBorderColor;
      }
    }

    &:first-child {
      border: 1px solid $defaultBorderColor;
      background-color: $white;
      flex: 0 0 30vw;
      .list {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        > div {
          flex: 0 0 auto;
          display: flex;
          padding: $defaultPadding $defaultPadding $smallPadding $defaultPadding;
          transition: background-color $defaultTransitionDuration;
          cursor: pointer;
          &.selected {
            background-color: $grey300;
          }
          &:hover {
            background-color: $grey300;
          }
          > div {
            flex: 1;
            white-space: nowrap;
            &:first-child {
              flex: 0 0 90px;
            }
            &:nth-child(2) {
              flex: 0 0 80px;
            }
            &:nth-child(4) {
              flex: 0 0 30px;
              color: $grey500;
            }
            &:nth-child(5) {
              flex: 0 0 30px;
              color: $grey500;
            }
          }
        }
      }
    }
    &:last-child {
      flex: 1;
      display: flex;
    }
  }
}
