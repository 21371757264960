@import 'variables';
@import '../table.scss';

.design-header-comp {
  @include design-table;
  border-top: 1px solid $defaultBorderColor;
  border-bottom: 1px solid $defaultBorderColor;
  padding: 0 $pagePadding 0 $pagePadding;
  background-color: $white;
  flex: 1;
  &.disabled {
    color: red;
    background-color: green;
  }
  > div {
    @include text-heading();
    border-bottom: none;
  }
}
