@import 'variables';
$segUpperHeight: 30vh;

.select-one-comp {
  background: $white;
  display: flex;
  flex: 1;
  flex-direction: column;
  &>div {
    display: flex;
    flex: 1;
    @include box();
    font-size: 110%;
    &:first-child {
      padding: $defaultPadding;
      text-transform: uppercase;
      color: $tableHeaderColor;
      font-weight: bold;
      display: flex;
      flex: 0 0 auto;
    }
    &:last-child {
      display: flex;
      flex-direction: column;
      &>.list {
        flex: 0 0 calc(#{$segUpperHeight} - #{$defaultPadding} - #{$defaultPadding} - 60px);
        overflow: auto;
        @media (max-height: 699px) {
          flex: 0 0 170px;
        }
      }
    }
  }
}
