@import 'variables';

.land-p {
  .landing-page-content {
    >div {
      padding: 0;
      background-color: transparent;
    }
  }
  .topbar {
    >div {
      background-color: transparent;
    }
  }
}
