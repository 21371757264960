@import 'variables';

.user-edit-view {
  > div {
    .header {
      @include heading();
    }
    &:last-child {
      padding: $defaultPadding $defaultPadding $defaultPadding $largePadding;
    }
    .sessions {
      max-height: 20vh;
      overflow: auto;
      background-color: $white;
      margin-top: $defaultPadding;
      padding: $defaultPadding;
      display: flex;
      flex: 1;

      flex-direction: column;
      > div {
        &.header {
          color: $grey500;
          text-transform: uppercase;
          font-size: 80%;
          font-weight: normal;
        }
        padding: $defaultPadding;
        display: flex;
        flex: 1;
        > div {
          flex: 1;
          &:first-child {
            flex: 0 0 30vw;
          }
          &:last-child {
            flex: 0 0 30px;
            color: $grey500;
          }
        }
      }
    }
  }
}
