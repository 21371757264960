@mixin cell() {
  overflow: hidden;
  white-space: nowrap;
  padding: $defaultPadding $smallPadding;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

@mixin design-table() {
  display: flex;
  &>div {
    flex: 1;
    @include cell;
    &:nth-child(1) { flex: 0 0 5vw; }
    &:nth-child(2) { flex: 0 0 9vw; }
    &:nth-child(3) { flex: 0 0 11vw; }
    &:nth-child(4) { flex: 0 0 6vw; border-right: 1px solid $defaultBorderColor; }
    &:nth-child(5) { flex: 0 0 3vw; }
    &:nth-child(6) { flex: 0 0 18vw; }
    &:nth-child(7) { flex: 0 0 8vw; }
    &:nth-child(8) { flex: 0 0 8vw; }
    &:nth-child(9) { flex: 0 0 8vw; }
    &:nth-child(10) { flex: 0 0 7vw; }
    &:nth-child(11) { flex: 0 0 8vw; }
    &:nth-child(12) { flex: 1; }
  }
}
@mixin design-table-edit-panel() {
  flex: 0 0 65vw;
}