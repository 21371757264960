@import 'variables';

.txt-cmp {
  > div {
    display: inline-block;
    &.short {
      max-width: 150px;
      width: 150px;
      textarea {
        max-width: 150px;
        width: 150px;
      }
    }
    &.wide {
      max-width: 550px;
      width: 550px;
      textarea {
        max-width: 550px;
        width: 550px;
      }
    }
    &.x-wide {
      max-width: 700px;
      width: 700px;
      textarea {
        max-width: 550px;
        width: 550px;
      }
    }
    &.normal {
      max-width: 200px;
      width: 200px;
      textarea {
        max-width: 200px;
        width: 200px;
      }
    }
    &.none, &.flex {
      flex: 1;
      display: flex;
      textarea {
        flex: 1
      }
    }
    position: relative;
    margin: 0 $smallPadding $smallPadding 0;
  }
  &.disabled {
    cursor: not-allowed;
    input {
      cursor: not-allowed;
    }
  }

  label {
    color: $formLabelColor;
    position: absolute;
    top: 20px;
    left: 0;
    padding: 0 0 0 $mediumPadding;
    opacity: 0.5;
    transition: all $defaultTransitionDuration;
  }
  textarea:focus + label {
    color: $accentColor;
  }
  textarea:focus {
    outline: none;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.15);
  }
  textarea:focus + label,
  textarea:valid + label {
    font-size: 75%;
    font-weight: bold;
    transform: translate3d(0, -120%, 0);
    opacity: 1;
  }
  
  textarea {
    border: none;
    border-radius: $defaultBorderRadius;
    padding: $largePadding $defaultPadding $defaultPadding $defaultPadding;
    font: 100% 'Trade Gothic', Helvetica, sans-serif;
  }
}
