@import 'variables';

.active-cmp {
  @include box();
  background-color: white;
  display: flex;
  flex-direction: column;

  &>div {
    flex: 1;
    padding: $defaultPadding;
    &:first-child {
      @include list-heading();
      display: flex;
      flex: 0 0 auto;
    }
    &:last-child {
      font-size: 120%;
      input {
        padding: $smallPadding $defaultPadding;
        font-size: 100%;
      }
    }
  }
}
