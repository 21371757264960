@import 'variables';

.machine-types-view {
  padding: 0 $pagePadding;

  .selected-machine-type {
    &.card {
      background-color: $grey200;
      padding: $defaultPadding;
    }
  }
}
