@import 'variables';

.lpov {
  padding: $defaultPadding $pagePadding 0 $pagePadding;
  >div {
    display: flex;
    >:first-child {
      @include shadowed();
      flex: 1 1 70%;

      background-color: $white;
      padding: $defaultPadding;
    }
    >:last-child {
      @include shadowed();
      flex: 1 1 30%;
      background-color: $white;
      padding: $defaultPadding;
      margin-left: $defaultPadding;
    }
    .badge-label {
      font-family: 'Trade Gothic', sans-serif;
      font-weight: 100 !important;
    }
    ul {
      margin: 30px;
      list-style: none;
      li {
        margin-bottom: $defaultPadding;
        span {
          font-size: 160%;
          color: $grey;
        }
        div {
          font-size: 120%;
          background: $grey;
          border-radius: 34px;
          display: inline-block;
          padding: $defaultPadding;
          color: $white;
        }
      }
    }
  }
}
