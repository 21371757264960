@import 'variables';

.ic {
  &.disabled {
    cursor: not-allowed;
    input {
      cursor: not-allowed;
    }
  }

  label {
    @include form-label();
    display: block;
  }

  &.on-white {
    input {
      border-top: 1px solid $bgfill;
      border-left: 1px solid $bgfill;
      border-bottom: 1px solid $grey200;
      border-right: 1px solid $grey200;
      border-radius: $defaultBorderRadius;
    }
  }
  &:not(.on-white) {
    input {
      border: 0;
    }
  }
  input {
    background-color: transparent;
    padding: $mediumPadding $defaultPadding;

    font: 100% 'Trade Gothic', Helvetica, sans-serif;
    transition: $defaultTransitionDuration all;
    &:focus {
      animation: simpleinputcolorchange 0.5s;
      outline: none;
    }
  }
}
