@import 'variables';

.modal-box-c {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  opacity: 0;
  transition: all 0.3s;
  visibility: hidden;
  &.visible {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
  &>div {
    width: 60vw;
    max-width: 800px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: $defaultPadding;
    background: #ffffff;
    max-height: 99vh;
    overflow: auto;
  }
}
