@import 'variables';
.rec-sel-view {
  display: flex;
  flex-direction: column;
  width: 100vw;
  &>div {
    &:first-child {
      flex: 0 0 calc(30vh - #{$menuHeight});
      display: flex;
    }
    &:last-child {
      display: flex;
      flex: 1;
    }
  }

  .crit-sel-view {
    display: flex;
    flex: 1;
    &>div {
      flex: 1;
      padding: $defaultPadding $pagePadding $defaultPadding $pagePadding;
      justify-content: stretch;
      display: flex;
      &>div {
        flex: 1;
        &:not(:last-child) {
          margin-right: $defaultPadding;
        }
      }
    }
  }
  
}
