@import 'variables';

.copy-view {
  .actions {
    border: 4ps solid red;
    button {
      margin: $defaultPadding $defaultPadding 0 0;
    }
  }
}
