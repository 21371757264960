@import 'variables';

.finish-form {
  .name-and-subtitle {
    display: flex;

    > * {
      flex-grow: 1;

      input {
        width: 100%;
      }
    }
  }

  .ui-order {
    margin-top: $mediumPadding;

    .ic {
      margin-right: $mediumPadding;

      input {
        width: 5rem;
      }
    }
  }

  
}
