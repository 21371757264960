@import 'variables';

.machineinformation {
  .machine-content-wrapper {
    display: flex;
    flex-direction: column;

    .card {
      padding: $defaultPadding;
    }

    .cols {
      display: flex;
      .col {
        flex: 1;
        display: flex;
        justify-content: space-between;
        margin-top: $largePadding;
        flex-direction: column;
        > div {
          margin: 0 $defaultPadding $defaultPadding 0;
        }
        h3 {
          margin: 0 0 $defaultPadding;
        }
      }
    }
  }
}
