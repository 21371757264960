@import 'variables';

.tool-systems-view {
  padding: 0 $pagePadding;

  .selected-tool-system {
    >.card {
      background-color: $grey200;
      padding: $defaultPadding;
      margin-bottom: $defaultPadding;
    }

    .image {
      background-color: $white;
    }
  }
}
