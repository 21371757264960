@import 'variables';
// https://medium.muz.li/30-handpicked-excellent-dashboards-347e2407a057


.tom {
  display: flex;
  flex-direction: column;
  height: 100vh;
  &>div {
    &:first-child {
      flex: 0 0 $menuHeight;
    }
    &:last-child {
      display: flex;
      flex: 1;
    }
  }
}
