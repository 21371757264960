@import 'variables';

.content-view {
  width: 100%;
  display: flex;
  padding: $defaultPadding $pagePadding $defaultPadding $pagePadding;

  .content-view-content {
    flex-grow: 1;

    .two-part {
      display: flex;
      justify-content: space-between;

      > * {
        flex-grow: 1;
        flex-basis: 50%;

        &:not(:last-of-type) {
          margin-right: $pagePadding;
        }
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: $largePadding;

      button {
        outline: none;
      }
    }
  }
}
