@import 'variables';

.finishes-view {
  padding: 0 $pagePadding;

  >.card {
    background-color: $grey200;
    padding: $defaultPadding;
    margin-bottom: $defaultPadding;
  }

  .image {
    background-color: $white;
  }
}
