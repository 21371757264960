@import 'variables';

.tool-cmp {
  padding: $defaultPadding $pagePadding;
  display: flex;
  flex: 1;
  flex-direction: column;
  > div {
    &:last-child {
      flex: 1;
    }
  }
  
  .double-cols{
    display: flex;
    flex: 1;
    > div {
      &:first-child {
        flex: 1;
      }
      &:last-child {
        text-align: center;
      }
      flex: 1;
    }
  }

  .more-action {
    color: $linkColor;
    cursor: pointer;
    i {
      margin-left: $smallPadding;
      opacity: .5;
    }
    &:hover {
      color: $accentColor;
      i {
        opacity: 1;
      }
    }
  }
  .usage {
    margin: $defaultPadding 0;
  }
}
