$white: #fff;
$bgfill: #f3f2f0;
$grey900: #112;
$grey800: #201e1e;
$grey700: #2c2c2c;
$grey400: #b0b0b1;
$grey500: #595959;
$grey300: #d2d2d2;
$grey200: #e2e3e6;

$hqvblue: #273a60;
$blue500: #07afdc;
$orange500: #dc2832;
$green500: #31b900;
$bggrey1: $grey200;
$formLabelColor: rgba(32, 30, 30, 0.7);

$accentColor: #f35321;
$accentColorDisabled: #eca994;
$accentColorActive: #eb410c;
$textOnDark: $white;
$linkOnDark: $grey300;
$menuBg: $grey800;
$menuHeight: 68px;
$inputFocusColor: $grey200;

$blackgrey: $grey700;
$deepgrey: #393939;
$grey: #595959;

$linkColor: #3a619f;
$tableHeaderColor: $grey500;
$defaultBorderColor: $grey300;
$pagePadding: 4vw;
$largePadding: 22px;
$defaultPadding: 16px;
$mediumPadding: 12px;
$tablePadding: 22px;
$smallPadding: 8px;
$listPaddingVertical: 11px;

$defaultTransitionDuration: 0.2s;

$defaultBorderRadius: 4px;
$buttonBorderLargeRadius: 8px;
$buttonBorderRadius: $defaultBorderRadius;
$inputBorderRadius: $defaultBorderRadius;

@mixin box($borderColor: $defaultBorderColor) {
  border: 1px solid $borderColor;
  border-radius: $inputBorderRadius;
  background-color: $white;
  -webkit-box-shadow: 2px 2px 8px 3px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 2px 2px 8px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 2px 2px 8px 3px rgba(0, 0, 0, 0.03);
}

@mixin shadowed() {
  -webkit-box-shadow: 5px 5px 13px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 5px 5px 13px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 5px 5px 13px 3px rgba(0, 0, 0, 0.15);
}

@mixin light-shadowed() {
  -webkit-box-shadow: 5px 5px 13px 3px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 5px 5px 13px 3px rgba(0, 0, 0, 0.07);
  box-shadow: 3px 3px 8px 3px rgba(0, 0, 0, 0.07);
}

@mixin heading($fontSize: 140%, $paddingTop: $largePadding) {
  @include text-heading($fontSize);
  padding-top: $paddingTop;
  padding-bottom: $smallPadding;
}

@mixin text-heading($fontSize: 110%) {
  font-size: $fontSize;
  font-weight: bold;
  color: $grey800;
  text-transform: uppercase;
}

@mixin list-heading() {
  @include text-heading();
  padding: $listPaddingVertical $defaultPadding;
  border-bottom: 1px solid $defaultBorderColor;
}

@mixin form-label() {
  height: 14px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $formLabelColor;
}