@import 'variables';

.search-result-list {
  ul {
    list-style: none;

    li {
      list-style: none;
      cursor: pointer;
      padding: $smallPadding 0;
    }
  }
}
