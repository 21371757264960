@import 'variables';
.w {
  display: flex;
  flex-direction: column;
  overflow: clip;
  img{
    flex: 1
  }
  &.delayed {
    animation: slowReveal 1.5s 1 linear;
    @keyframes slowReveal {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &.small {
    .loader,
    .loader:after {
      border-radius: 50%;
      width: 2em;
      height: 2em;
    }
    .loader {
      margin: $smallPadding auto;
      border-top: .5em solid rgba(190, 190, 190, 0.2);
      border-right: .5em solid rgba(190, 190, 190, 0.2);
      border-bottom: .5em solid rgba(190, 190, 190, 0.2);
      border-left: .5em solid $grey300;
    }
  }

  &:not(.small) {
    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
    .loader {
      margin: $pagePadding auto;
      border-top: 1.1em solid rgba(190, 190, 190, 0.2);
      border-right: 1.1em solid rgba(190, 190, 190, 0.2);
      border-bottom: 1.1em solid rgba(190, 190, 190, 0.2);
      border-left: 1.1em solid $grey300;
    }
  }

  .loader {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1s infinite linear;
    animation: load8 1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

}
