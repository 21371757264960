@import 'variables';
$segUpperHeight: 30vh;

.selectables-comp {
  background: $white;
  display: flex;
  flex: 1;
  flex-direction: column;
  &>div {
    border: 1px solid $defaultBorderColor;
    background-color: $white;
    box-shadow: 2px 2px 8px 3px rgba(0, 0, 0, 0.03);

    display: flex;
    flex: 1;
    font-size: 110%;
    &:first-child {
      @include list-heading();
      display: flex;
      flex: 0 0 auto;
    }
    &:last-child {
      border-top: none;
      display: flex;
      flex-direction: column;
      &>.list {
        flex: 0 0 calc(#{$segUpperHeight} - #{$defaultPadding} - #{$defaultPadding} - 60px);
        overflow: auto;
        @media (max-height: 699px) {
          flex: 0 0 170px;
        }
      }
    }
  }
}
