@import 'variables';

.modal-c {
  header {
    display: flex;
    h1 {
      @include heading(140%, 0);
    }
    &>* {
      &:first-child {
        flex: 5;
      }
      &:last-child {
        flex: 0 0 20px;
        text-align: right;
        cursor: pointer;
        transition: color $defaultTransitionDuration;
        &:hover {
          color: $accentColor;
        }
      }
    }
  }
  section {
    max-height: 70vh;
    max-width: 90vw;
    overflow: auto;
  }
  footer {
    padding-top: $defaultPadding;
    display: flex;
    &>*{
      flex: 1;
      &:not(:last-child) {
        margin-right: $defaultPadding;
      }
    }
  }
}
