@import 'variables';

.step-edit-cmp {
  background-color: $grey200;
  flex: 1;
  display: flex;
  > div {
    display: flex;
    flex: 1;
    margin-right: $pagePadding;
    margin-bottom: $defaultPadding;
    flex-direction: column;
    &>div {
      flex: 1;
      display: flex;
      &>div {
        flex: 1;
        padding: $defaultPadding;
        &:not(:last-child) {
          padding-right: 0;
        }
        &.actions {
          text-align: right;
          &>* {
            margin-left: $defaultPadding;
          }
        }
      }
    }
  }
}
