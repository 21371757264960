@import 'variables';

// Include .card to make this rule overwrite default .card rule
.step-data.card {
  .title-wrapper {
    display: flex;
    justify-content: space-between;

    .locked {
      text-transform: uppercase;
      color: $grey400;
    }
  }

  > .card {
    > div:not(:last-of-type) {
      border-bottom: 1px solid #000;
      padding-bottom: $defaultPadding;
    }

    > div:not(:first-of-type) {
      padding-top: $defaultPadding;
    }
  }
}
