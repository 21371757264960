@import 'variables';

.rd-create-cp {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &>div {
    padding-top: $pagePadding;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
