@import 'variables';

.configurations {
  .subtitle {
    color: $grey400;
  }

  .config-table {
    display: grid;
    grid-template-columns: 40px repeat(6, 1fr);
    border: 1px solid $blackgrey;
    margin-top: $defaultPadding;
    text-align: center;

    > * {
      // border: 1px solid $blackgrey;
      border-right: none;
      padding: $smallPadding;      
      border-bottom: 1px solid $defaultBorderColor;
      border-left: 1px solid $defaultBorderColor;
    

      &.config {
        display: flex;
        &:not(.has-recipe) {
          cursor: pointer;
          transition: background-color $defaultTransitionDuration;
          &:hover {
            background-color: $bggrey1;
          }
        }

        > div {
          flex: 1;
          &:last-child {
            color: $grey400;
          }
        }
      }
    }
    .header-row {
      font-weight: bold;
    }
    .qty {
      border-left: none;
    }
  }
}
