@import 'variables';
.tmen {
    background-color: $menuBg;
    display: flex;

    .icon {
        flex: 0 0 40px;
        vertical-align: middle;
        padding: 14px $defaultPadding 0 $pagePadding;
        img {
            width: 40px;
            height: 40px;
            transform: rotate(-16deg);
        }
    }
    &>.tos {
        flex: 0 0 auto;
        padding: 22px $pagePadding 0 $defaultPadding;
        &>a {
            font-size: 130%;
            font-weight: bold;
            color: $grey200;
            text-transform: uppercase;
            &:hover {
                color: $grey200;
            }
        }
    }
    &>nav {
        flex: 0 0 auto;
        display: flex;
        justify-content: flex-start;

        &>a {
            white-space: nowrap;
            flex: 1;
            color: $linkOnDark;
            font-size: 110%;
            transition: $defaultTransitionDuration all;
            text-transform: uppercase;

            color: $linkOnDark;
            padding: 25px 2vw 20px 2vw;

            &:hover {
                color: $textOnDark;
                background-color: $grey700;
            }
            &.act {
                color: $white;
                background-color: $accentColor;
            }
        }
    }
    .hqv-icon {
        flex: 1;
        vertical-align: middle;
        padding: 20px $pagePadding 0 $pagePadding;
        text-align: right;
        img {
            height: 30px;
        }
    }
}
